.tab-content {
    margin-top: 25px;
    margin-left: 45px;
    margin-right: 10%;
}

.vl {
    border-left: 1px solid lightgrey;
    height: 3000px;
    margin-left: 15px;
    margin-top: 83px;
    position: absolute;
    top: 0;
}

.bld {
    font-weight:  bold;
}

/*
    @see https://www.30secondsofcode.org/css/s/hover-underline-animation/
*/
/*noinspection CssUnusedSymbol*/
.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

/*noinspection CssUnusedSymbol*/
.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

/*noinspection CssUnusedSymbol*/
.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/*noinspection CssUnusedSymbol*/
a.hover-underline-animation:hover, a.hover-underline-animation:visited, a.hover-underline-animation:link, a.hover-underline-animation:active
{
    text-decoration: none;
}

li.no-marker::marker {
    content: none;
}

/*noinspection CssUnusedSymbol*/
li.rx-icon::marker {
    content: "💊 ";
    color: chartreuse;
}

li.save-icon::marker {
    content: "💾 ";
    color: chartreuse;
}
