@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
/* Toggle */
.neu-font {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.neu-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #394a56;
}

.neu-label-text-right {
    margin-left: 16px;
}
.neu-label-text-left {
    margin-right: 16px;
}
.neu-label-text-left-disabled {
    margin-right: 16px;
    cursor: not-allowed;
}

.neu-toggle {
    isolation: isolate;
    position: relative;
    height: 15px;
    width: 30px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow:
            -4px -2px 4px 0 #ffffff,
            4px 2px 6px 0 #d1d9e6,
            2px 2px 2px 0 #d1d9e6 inset,
            -2px -2px 2px 0 #ffffff inset;
}

.neu-toggle-state {
    display: none;
}

.neu-indicator {
    height: 100%;
    width: 200%;
    background: #ecf0f3;
    border-radius: 15px;
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0);
    transition: -webkit-transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35), -webkit-transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    box-shadow:
            -4px -2px 4px 0 #ffffff,
            4px 2px 6px 0 #d1d9e6;
}

.neu-toggle-state:checked ~ .neu-indicator {
    -webkit-transform: translate3d(25%, 0, 0);
            transform: translate3d(25%, 0, 0);
}

.neu-toggle-state:disabled ~ .neu-indicator {
    cursor: not-allowed;
    background: #d1d9e6;
}

/* Uncomment below to show color when switch is checked */
/*.neu-toggle-state:checked ~ .neu-indicator {*/
/*    background: #a3aab9;*/
/*}*/

.settings-container {
    background: #dde1e7;
    margin-left: 0;
    box-sizing: border-box;
    padding-top: 35px;
    padding-left: 350px;
    padding-bottom: 135px;
}

.settings-content {
    margin-top: 145px;
    margin-bottom: 125px;
    margin-left: 10px;
    width: 425px;
    background: #dde1e7;
    border-radius: 10px;
    padding: 40px 30px;
    box-shadow: -3px -3px 7px #ffffff73,
    2px 2px 5px rgba(94, 104, 121, 0.288);
}

.settings-grid {
    display: grid;
    grid-template-columns: 120px auto;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    font-family: Poppins, serif;
}

.settings-display {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 5px;
}

.row1-col1 { grid-area: 1 / 1 / 2 / 2; }
.row1-col2 { grid-area: 1 / 2 / 2 / 3; }
.row2-col1 { grid-area: 2 / 1 / 3 / 2; }
.row2-col2 { grid-area: 2 / 2 / 3 / 3; }
.row3-col1 { grid-area: 3 / 1 / 4 / 2; }
.row3-col2 { grid-area: 3 / 2 / 4 / 3; }
.row4-col1 { grid-area: 4 / 1 / 5 / 2; }
.row4-col2 { grid-area: 4 / 2 / 5 / 3; }
.row5-col1 { grid-area: 5 / 1 / 6 / 2; }
.row5-col2 { grid-area: 5 / 2 / 6 / 3; }

.settings-text-header {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #000;
    text-align: center;
}

.tab-content {
    margin-top: 25px;
    margin-left: 45px;
    margin-right: 10%;
}

.vl {
    border-left: 1px solid lightgrey;
    height: 3000px;
    margin-left: 15px;
    margin-top: 83px;
    position: absolute;
    top: 0;
}

.bld {
    font-weight:  bold;
}

/*
    @see https://www.30secondsofcode.org/css/s/hover-underline-animation/
*/
/*noinspection CssUnusedSymbol*/
.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

/*noinspection CssUnusedSymbol*/
.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

/*noinspection CssUnusedSymbol*/
.hover-underline-animation:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
}

/*noinspection CssUnusedSymbol*/
a.hover-underline-animation:hover, a.hover-underline-animation:visited, a.hover-underline-animation:link, a.hover-underline-animation:active
{
    text-decoration: none;
}

li.no-marker::marker {
    content: none;
}

/*noinspection CssUnusedSymbol*/
li.rx-icon::marker {
    content: "💊 ";
    color: chartreuse;
}

li.save-icon::marker {
    content: "💾 ";
    color: chartreuse;
}

.hover-shadow-box-animation {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    margin: 10px;
    transition-duration: 0.3s;
    transition-property: box-shadow, -webkit-transform;
    transition-property: box-shadow, transform;
    transition-property: box-shadow, transform, -webkit-transform;
}

.hover-shadow-box-animation:hover,
.hover-shadow-box-animation:focus,
.hover-shadow-box-animation:active {
    box-shadow: 1px 10px 10px -10px rgba(0, 0, 24, 0.5);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.pb-group-item {
    padding-top: 0.25rem;
    padding-right: 1.25rem;
    padding-bottom: 0.20rem;
    padding-left: 1.25rem;
}


.neu-main {
    display: grid;
    place-items: center;
    text-align: center;
    background: #dde1e7;
    margin-left: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.neu-content {
    margin-top: 200px;
    margin-bottom: 235px;
    width: 350px;
    background: #dde1e7;
    border-radius: 10px;
    padding: 40px 30px;
    box-shadow: -3px -3px 7px #ffffff73,
    2px 2px 5px rgba(94, 104, 121, 0.288);
}

.neu-content img
{
    width: 90px;
    height: 90px;
    margin-top: -100px;
    border-radius: 50px;
    box-shadow:
            0 0 2px #5f5f5f,
            0 0 0 5px #ecf0f3,
            8px 8px 15px #a7aaaf,
            -8px -8px 15px #ffffff;
    cursor: pointer;
    pointer-events: all;
}


.neu-content .neu-field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
}

.neu-content .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #000;
}

.neu-content-large .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #000;
}

/*noinspection CssUnusedSymbol*/
.neu-button {
    margin: 25px 0 0 0;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background: #dde1e7;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 25px;
    box-shadow: 2px 2px 5px #babecc,
    -5px -5px 10px #ffffff73;
}

.neu-button:focus {
    color: #3498db;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
}

.neu-button:disabled {
    color: #babecc;
    cursor: default;
}

.neu-field input {
    height: 100%;
    width: 100%;
    padding-left: 45px;
    font-size: 18px;
    outline: none;
    border: none;
    color: #595959;
    background: #dde1e7;
    border-radius: 25px;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
}

.neu-field input:focus ~ label {
    box-shadow: inset 2px 2px 5px #babecc,
    inset -1px -1px 2px #ffffff73;
}

.neu-field:nth-child(2) {
    margin-top: 20px;
}

/* Font Awesome <span className='fa fa-icon'/> */
.neu-field span {
    position: absolute;
    width: 50px;
    line-height: 50px;
    color: #595959;
}

.neu-field svg {
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #595959;
}

.neu-field input::-webkit-input-placeholder {
    position: absolute;
    top: 50%;
    left: 45px;
    pointer-events: none;
    color: #666666;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.neu-field input::placeholder {
    position: absolute;
    top: 50%;
    left: 45px;
    pointer-events: none;
    color: #666666;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

