.settings-container {
    background: #dde1e7;
    margin-left: 0;
    box-sizing: border-box;
    padding-top: 35px;
    padding-left: 350px;
    padding-bottom: 135px;
}

.settings-content {
    margin-top: 145px;
    margin-bottom: 125px;
    margin-left: 10px;
    width: 425px;
    background: #dde1e7;
    border-radius: 10px;
    padding: 40px 30px;
    box-shadow: -3px -3px 7px #ffffff73,
    2px 2px 5px rgba(94, 104, 121, 0.288);
}

.settings-grid {
    display: grid;
    grid-template-columns: 120px auto;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    font-family: Poppins, serif;
}

.settings-display {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 5px;
}

.row1-col1 { grid-area: 1 / 1 / 2 / 2; }
.row1-col2 { grid-area: 1 / 2 / 2 / 3; }
.row2-col1 { grid-area: 2 / 1 / 3 / 2; }
.row2-col2 { grid-area: 2 / 2 / 3 / 3; }
.row3-col1 { grid-area: 3 / 1 / 4 / 2; }
.row3-col2 { grid-area: 3 / 2 / 4 / 3; }
.row4-col1 { grid-area: 4 / 1 / 5 / 2; }
.row4-col2 { grid-area: 4 / 2 / 5 / 3; }
.row5-col1 { grid-area: 5 / 1 / 6 / 2; }
.row5-col2 { grid-area: 5 / 2 / 6 / 3; }

.settings-text-header {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #000;
    text-align: center;
}
