@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
.neu-main {
    display: grid;
    place-items: center;
    text-align: center;
    background: #dde1e7;
    margin-left: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.neu-content {
    margin-top: 200px;
    margin-bottom: 235px;
    width: 350px;
    background: #dde1e7;
    border-radius: 10px;
    padding: 40px 30px;
    box-shadow: -3px -3px 7px #ffffff73,
    2px 2px 5px rgba(94, 104, 121, 0.288);
}

.neu-content img
{
    width: 90px;
    height: 90px;
    margin-top: -100px;
    border-radius: 50px;
    box-shadow:
            0 0 2px #5f5f5f,
            0 0 0 5px #ecf0f3,
            8px 8px 15px #a7aaaf,
            -8px -8px 15px #ffffff;
    cursor: pointer;
    pointer-events: all;
}


.neu-content .neu-field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
}

.neu-content .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #000;
}

.neu-content-large .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #000;
}

/*noinspection CssUnusedSymbol*/
.neu-button {
    margin: 25px 0 0 0;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background: #dde1e7;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 25px;
    box-shadow: 2px 2px 5px #babecc,
    -5px -5px 10px #ffffff73;
}

.neu-button:focus {
    color: #3498db;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
}

.neu-button:disabled {
    color: #babecc;
    cursor: default;
}

.neu-field input {
    height: 100%;
    width: 100%;
    padding-left: 45px;
    font-size: 18px;
    outline: none;
    border: none;
    color: #595959;
    background: #dde1e7;
    border-radius: 25px;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
}

.neu-field input:focus ~ label {
    box-shadow: inset 2px 2px 5px #babecc,
    inset -1px -1px 2px #ffffff73;
}

.neu-field:nth-child(2) {
    margin-top: 20px;
}

/* Font Awesome <span className='fa fa-icon'/> */
.neu-field span {
    position: absolute;
    width: 50px;
    line-height: 50px;
    color: #595959;
}

.neu-field svg {
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #595959;
}

.neu-field input::placeholder {
    position: absolute;
    top: 50%;
    left: 45px;
    pointer-events: none;
    color: #666666;
    transform: translateY(-50%);
}
