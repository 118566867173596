.hover-shadow-box-animation {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    margin: 10px;
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
}

.hover-shadow-box-animation:hover,
.hover-shadow-box-animation:focus,
.hover-shadow-box-animation:active {
    box-shadow: 1px 10px 10px -10px rgba(0, 0, 24, 0.5);
    transform: scale(1.2);
}
